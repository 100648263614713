<template>
    <div></div>
</template>
<script>
import * as echarts from 'echarts';
import { defineComponent } from 'vue'
// import ECharts from 'vue-echarts'
// import { use } from 'echarts/core'
// import { CanvasRenderer } from 'echarts/renderers'
// import { BarChart } from 'echarts/charts'
// import { GridComponent, TooltipComponent } from 'echarts/components'

// use([
//   CanvasRenderer,
//   BarChart,
//   GridComponent,
//   TooltipComponent
// ]);

export default defineComponent({
    name: 'echarts',
    props: {
        option:{
            type:Object
        },
    },
    data() {
        return {
            myChart:null
        }
    },
    watch:{
        option:{
          handler(newVal, oldVal) {
            // 确保 myChart 存在
            if (this.myChart) {
              this.myChart.setOption(newVal, true);
            }
          },
          deep:true,
          immediate:true
        }
    },
    mounted(){
      const _T = this
      this.myChart = echarts.init(this.$el);
      window.addEventListener('resize', function() {
        _T.myChart.resize();
      });
    },
})
</script>