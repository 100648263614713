import router from "@/router";

import * as $axios from '@/api/index.js'
import { ref } from "vue";
import { sleep } from "../utils";
import { changeQiankunLoading } from ".";
let entryUrl = __51__commonUtil.__hallUrlMap__.baoxian_url;
// let entryUrl = `http://localhost:8088/`;
let baseInfo = JSON.parse(localStorage.getItem('baseInfo'));
//将对象转成url参数
function urlToParams(params){
	let url = '';
    if (params) {
		
		let arr = []
	 
		for (let k in params) {
			arr.push(`${k}=${params[k]}`)
		}
			console.log(arr.join('&')) 
	 
			url += `?${arr.join('&')}` 
	}
	return url;
}
const initState = {
	// 示例
	location: {
	  // 服务单跳转
	  toServiceOrder: (state) => {
		router.push('/insurance/fe/workbenchServiceOrder/service_order/query');
	  },
	  // 跳转员工详情
	  async toEmployeeDetail(obj){
		let id = urlToParams(obj).split('=')[1];
		console.log($axios,'$axios');
		let res1 = await $axios.personGetPersonByCompanyUserId({companyUserId:id});
		if(res1.retCode == 1){
			let person_id = res1.data.id;   
			let sign_str = res1.data.signStr; 
			let workingStatus = res1.data.workingStatus;
			if(workingStatus != 4){
				window.location.href=`${__51__commonUtil.__hallUrlMap__.renshi_url}/rosterManagement/outsourceDetail?person_id=${person_id}&sign_str=${sign_str}&company_user_id=${id}`;
			}
		}
	  },
	  // 跳转工作台首页
	  toInsuranceHome(...arg) {
		router.push({name: '/index',});
		// router.push(`${__51__commonUtil.__hallUrlMap__.gongzuotai_url}`);
	  },
	},
  };
const apps = [
	/**
	 * name: 微应用名称 - 具有唯一性
	 * entry: 微应用入口 - 通过该地址加载微应用
	 * container: 微应用挂载节点 - 微应用加载完成后将挂载在该节点上
	 * activeRule: 微应用触发的路由规则 - 触发路由规则后将加载该微应用
	 */
	{
		name: 'vue-baoxian-101hr-com',
		entry: `${entryUrl}/fe`,
		// entry: `http://localhost:8088/fe`,
		container: '#insurance',
		activeRule: '/insurance',
		props: {
			router,
			location: initState.location,
			__51__commonUtil: window.__51__commonUtil,
			loadedCallback: changeQiankunLoading
		},
	}
]
export default apps
