import Vue from 'vue'
import { defineStore, createPinia, PiniaVuePlugin } from 'pinia'
Vue.use(PiniaVuePlugin)

export const useAppStore = defineStore({
	id: 'app',
	state: () => ({
		baseInfo:{},
    isBaoxian: false,
	}),
	actions: {
		setBaseInfo(info){
			this.baseInfo = info;
		},
    getIsBaoxian(isBaoxian){
      this.isBaoxian = isBaoxian
		}
	}
})
