import req from '@/utils/request'

const BaseUrl = '/person'

export const getVersionNumber = params => req('get','/person/user/getVersionNumber',params)
//获取商户code接口
export const getCompanyId = params => req('get','/person/user/getCurrentCustomerCode',params)
// 登录用户信息
export const getBaseInfo = (params) => req('post', '/person/base/info', params);
// 获取用户绑定的公司列表
export const getCompanyInfo = (params) => req('get', '/person/user/account/bind/company/list', params); 
// 切换公司
export const changeCompany = params => req('post', '/person/user/change/company', params); 
// 检测token
export const getToken = params => req('get', '/person/user/getTtl', params); 

//获取未读消息数量
export const getMessageNum = params => req('get', '/person/user/unread/count', params); 

export const logout = () => req('get', '/person/user/logout');
// qimoor
// export const getQiMoorParams = (params, config) => req('get', `${BaseUrl}/qiMoorParams`, params, config)


// 是否显示同路账单
export const showTonglu = (params, config) => req('get', `/api/cost-center/common/isShowTongLuBill`, params, config)

//根据参保人id获取员工信息
export const personGetPersonByCompanyUserId = params => req('post','/person/getPersonByCompanyUserId',params)

//获取人员加密信息
export const personBaseGetPersonSignStr = params => req('post','/person/base/getPersonSignStr',params)
//上保险配置
export const configListByAdd = params => req('get','/person/config/configListByAdd',params)

//获取授权安装应用的链接
export const installGetAuthorizeLink = params => req('get','/person/pmoa/install/getAuthorizeLink',params)

//授权安装应用回调
export const installCallback = params => req('post','/person/pmoa/install/callback',params)

//构造登陆授权页面链接
export const getLoginAuthLink = params => req('get','/person/pmoa/user/getLoginAuthLink',params)
