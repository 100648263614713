import axios from 'axios'
import router from '../../node_modules/vue-router' // 引入router
// import qs from 'qs';
import {getHeader,getToken, getCompanyId,logoutToRemoveCookies} from '@/utils/uniCookie.js'
import { isRef } from 'vue'
import _ from 'lodash'
const isDingTalk = navigator.userAgent.toLowerCase().indexOf('dingtalk') !== -1;
import { message, Modal } from 'ant-design-vue'
const service = axios.create({
	// baseURL:!isDingTalk ? '/gongzuotai':'',
	timeout: 60000,
	headers: getHeader(),
})
const pendingRequests = new Map()
let modal = null;
// 请求拦截 req 数据处理
service.interceptors.request.use(
	config => {
		let token =getToken() ||'';
		if (token) {
			Object.assign(config.headers, getHeader())
		}
		if (config?.loading && isRef(config.loading)) config.loading.value = true
		let companyId = getCompanyId();
		if(window.companyId && companyId && companyId!= window.companyId){
			modal && modal.destroy();  
			modal = Modal.info({
				title: '温馨提示',
				content: '您的登录企业已发生变化，请刷新页面',
				okText: '刷新',
				onOk() {
					localStorage.setItem('currentNav',0);
					window.location.href='/';
				}
			})
	
		}
		// // 生成请求标识符
		// const requestId = JSON.stringify({ url: config.url, method: config.method })
		// // 如果当前请求已经存在，则取消之前的请求
		// if (pendingRequests.has(requestId)) {
		// 	const cancelToken = pendingRequests.get(requestId)
		// 	cancelToken.cancel('Duplicate request canceled')
		// 	pendingRequests.delete(requestId)
		// }
		// // 为当前请求创建一个新的cancel token
		// const cancelToken = axios.CancelToken.source()
		// config.cancelToken = cancelToken.token
		// // 将cancel token和标识符存储到Map对象中
		// pendingRequests.set(requestId, cancelToken)
		return config
	},
	error => {
		if (error?.config?.loading && isRef(error.config.loading)) error.config.loading.value = false
	}
)

const noCheckApi = '/account/recharge'

service.interceptors.response.use(
	response => {
		// 删除已完成的请求的cancel token和标识符
		const requestId = JSON.stringify({ url: response.config.url, method: response.config.method })
		pendingRequests.delete(requestId)

		if (response?.config?.loading && isRef(response.config.loading)) response.config.loading.value = false
		// 不需要做统一相应处理的接口
		const noCheckApi = "/company/service/types";
		const noCheckApi2 = "/prePerson/top/tip";

		if (response.config && response.config.url && response.config.url.indexOf(noCheckApi) != -1){
		return Promise.resolve(_D)
		}
		//
		if (response.config && response.config.url && response.config.url.indexOf(noCheckApi2) != -1){
		return Promise.resolve(_D)
		}
		let _D = response.data;
		if (_D.code == 22002 || _D.retCode == 22002 || _D.errorCode == 10001) {//退出或token过期
			localStorage.removeItem('baseInfo')
			logoutToRemoveCookies();
				message.destroy()
			// _.debounce( ()=>{
				message.error(_D.message || _D.msg);
			//   }, 10000 )

			const isWeCom = /wxwork/i.test(window.navigator.userAgent); 
			const isDingTalk = navigator.userAgent.toLowerCase().indexOf('dingtalk') !== -1;
			if(isWeCom || isDingTalk){
			  window.location.href = `${window.__51__commonUtil.__hallUrlMap__.gongzuotai_url}/?weixinLogout=1`;
			}else{
			  window.location.href = `${window.__51__commonUtil.__hallUrlMap__.home_url}/login?ttlurl=${window.location.host}${window.location.pathname}&loginout=1&reback=${encodeURIComponent(window.location.href)}`
			}

		}else if (_D.code == 22107 || _D.retCode == 22107) {//版本更新
			axios.get('/person/user/getVersionNumber', {
				headers: getHeader()
			}).then((res)=>{
				if (res.data.retCode == 1) {
					if(localStorage.getItem('version_number') != res.data.data) {
						localStorage.setItem('version_number', res.data.data)
						Modal.info({
							title: '版本更新提醒',
							content: _D.message || _D.msg,
							okText: '更新版本',
							onOk() {
								window.location.reload()
							}
						})
					}
				}
			})
		}else if(_D.code == 22101 || _D.retCode == 22101){//切换商户
			
			// return Promise.resolve(_D);
		}else if (_D.code == 22004 || _D.retCode == 22004) { // 获取数据越权后跳转至首页
			message.error(_D.message || _D.msg);
			setTimeout(() => {
				localStorage.setItem('currentNav',0);
				window.location.href = "/";
			}, 300);
		}else if(_D.code == 22001 || _D.retCode == 22001 || _D.retCode == 0 || _D.retCode == 23004){
			message.destroy()
			// _.debounce( ()=>{
				message.error(_D.message || _D.msg);
			//   }, 10000 )
		}else{
			
			if ((_D.message || _D.msg) && _D.retCode != 1&& _D.retCode != 27007) {
				message.destroy()
				// _.debounce( ()=>{
					message.error(_D.message || _D.msg);
				// }, 10000 )()
				}
		}
		return Promise.resolve(_D);
	},
	error => {
		const requestId = JSON.stringify({ url: error.config.url, method: error.config.method, data: error.config.data })
		pendingRequests.delete(requestId)

		if (error?.config?.loading && isRef(error.config.loading)) error.config.loading.value = false

		if (error.request && error.request.readyState == 4 && error.request.status == 0) {
			message.error('网络请求超时')
		} else if (error.response && error.response.status) {
			switch (error.response.status) {
				// 401: 未登录
				// 未登录则跳转登录页面，并携带当前页面的路径
				// 在登录成功后返回当前页面，这一步需要在登录页操作。
				case 401:
					window.location.href = `${window.__51__commonUtil.__hallUrlMap__.home_url}/login?ttlurl=${window.location.host}&loginout=1&reback=${window.location.href}`
					break
				// 403 token过期
				// 登录过期对用户进行提示
				// 清除本地token和清空vuex中token对象
				// 跳转登录页面
				case 403:
					message.error('登录过期，请重新登录') // 产品提供文案
					// 清除token
					logoutToRemoveCookies();

					localStorage.removeItem('customerBindSubjectList')
					// 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面
					setTimeout(() => {
						router.replace({
							path: '/login',
							query: {
								redirect: router.currentRoute.fullPath
							}
						})
					}, 1000)
					break
				// 404请求不存在
				case 404:
					message.error('网络请求不存在')
					break
				// 请求方法错误
				case 405:
					message.error('请求方法错误')
					break
				case 500:
					message.error('服务端出错')
					break
				case 501:
					message.error('网络未实现')
					break
				case 502:
					message.error('网络错误')
					break
				case 503:
					message.error('服务不可用')
					break
				case 504:
					message.error('网络超时')
					break
				case 505:
					message.error('http版本不支持该请求')
					break
				// 其他错误，直接抛出错误提示
				default:
					message.error('系统异常')
			}
		} else {
			message.destroy()
			message.error(error.message)
		}
		return Promise.reject(error.response)
	}
)

function req(method, apiName, data = null, config = {}) {
	// Base_Url
	method = method.toLowerCase()
	const customConfig = {}
	if (config?.loading && isRef(config.loading)) {
		customConfig.loading = config.loading
	}
	if (config?.dom) {
		customConfig.dom = config.dom
	}
	if (method == 'post') {
		// 文件上传 采用 单独设置header Content-Type:multipart/form-data 方式
		//return service.post(apiName, qs.stringify(data), {
		customConfig.headers = {
			'Content-Type': config?.contentType || 'application/json;charset=utf-8',
			...getHeader()
		}
		customConfig.timeout = config?.timeout || 60000
		return service.post(apiName, data, customConfig)
	} else if (method == 'get') {
		return service.get(
			apiName,
			{
				params: data
			},
			customConfig
		)
	}
}
export default req
