import Vue from 'vue'
import App from './app.vue'
import {getToken,setToken,getCompanyId, setCompanyId} from '@/utils/uniCookie.js'
import sentryConfig from "../package.json"

import SvgIcon from '@/components/common/SvgIcon.vue'

// import 'core-js/stable'
// import 'regenerator-runtime/runtime'

import { createPinia, PiniaVuePlugin } from 'pinia'
Vue.use(PiniaVuePlugin)

Vue.component('SvgIcon', SvgIcon)

const pinia = createPinia()


// 注册skyWalking
import { useSkyWalking } from '@/utils/monitor'
useSkyWalking(Vue)
import router from './router/index.js'
import { useAppStore } from '@/store/'
  const appStore = useAppStore(pinia)

import * as $axios from './api/index.js'
import * as $utils from './utils/index.js'
Vue.prototype.$axios = $axios
Vue.prototype.$utils = $utils

// antd
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import './asset/css/index.scss'
import './asset/css/global.scss'
Vue.use(Antd)

import '@unocss/reset/normalize.css'

import 'virtual:uno.css'

import { message, Modal} from 'ant-design-vue'
Vue.prototype.$Modal = Modal
import infiniteScroll from 'vue-infinite-scroll';

import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import ConfigProvider from "ant-design-vue";
Vue.use(ConfigProvider)

import { FormModel } from 'ant-design-vue';
import { changeQiankunLoading } from './micro'
import SentryPerformance from './utils/sentry'

window.__currentHallUrl__ = window.__51__commonUtil.__hallUrlMap__.gongzuotai_url
import request from './utils/request'
window.lobbyRequest = request
// 静态调用 需要tla支持
import Layout from "lobby-mf/Layout"
Vue.component('mf-layout', Layout)

Vue.use(SentryPerformance,router);

Vue.use(FormModel)
if(import.meta.env.VITE_RUN_ENV == 'prod'){
    Sentry.init({
        app,
        dsn: "https://8e1cb07303fd4799bc97770022c03566@sentrypublic.51shebao.com/9",
        integrations: [
            new Integrations.Vue({
              app,
              attachProps: true
            })
        ],
        release: 'ssc@' + sentryConfig.version, 
        environment: 'production',
        tracesSampler: samplingContext => {
            return 1;  //按需设置0至1
        }
    });
}
let showNps = false;
const { getNpsInfo,showNpsBtn,hideNpsBtn ,getServeyInfo} = __51__commonUtil;
getServeyInfo();
if(['/stage'].includes(window.location.pathname)){
    setTimeout(()=>{
        showNps = true;
        getNpsInfo({
            type:'1',
            source:window.location.host
        });
    },180000);
}else{
    hideNpsBtn();
}
const getUrlParam = (name) =>{
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    var r = window.location.search.substr(1) ? window.location.search.substr(1).match(reg) : null;
    if (r != null) return unescape(r[2]);
    return null;
}
if(getUrlParam('nav')){
	localStorage.setItem('currentNav',getUrlParam('nav'));
}else{
	// localStorage.setItem('currentNav',0);
}
// 6.28 优化替代 vue-echarts 以解决tla冲突 使mf兼容chrome71
import ECharts from './components/common/echarts.vue'
Vue.component('v-chart', ECharts)

Vue.prototype.$message= message,

Vue.config.productionTip = false
Vue.config.devtools = true
const jsonpLogin = async () => {
    const resJsonP = await window.__51__commonUtil.webCoreCheckLogin();    
    if (!resJsonP || resJsonP.is_login == false) {
        // 未登录 跳转到登录页
        window.location.href = `${window.__51__commonUtil.__hallUrlMap__.home_url}/login?ttlurl=${window.location.host}&loginout=1&reback=${encodeURIComponent(window.location.href)}`
    } else if (resJsonP.is_login == true) {
        // 已登录 ticket换token
        $axios.getToken({ token: resJsonP.ticket }).then(async res => {
            if (res.retCode == 1) {
                setToken(res.data.ttl);
				renderVue();
			}else{
				console.error("ticket2token失败");
			}
		});
    }
};



function getQueryParam(name, url) {  
    if (!url) url = window.location.href;  
    const search = new URLSearchParams(url.split('?')[1]);  
    return search.get(name);  
  } 

const token = getToken();
import dd from 'dingtalk-jsapi';
const isMobile = window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i); // 判断移动端类型
const isWeCom = /wxwork/i.test(window.navigator.userAgent); // 判断是否为企业微信类	  
let corpId = getUrlParam('corpId') || '';
window.DTOpenData.init(corpId);
function isDingTalk() {
	const ua = navigator.userAgent.toLowerCase();
	return ua.indexOf('dingtalk') !== -1;
  }

  if(corpId){
	// dd.ready(function() {
		dd.runtime.permission.requestAuthCode({
			corpId: corpId, // 企业id
			onSuccess: function (info) {
				let code = info.code // 通过该免登授权码可以获取用户身份
				$axios.getLoginAuthLink({
					redirectUrl:window.__51__commonUtil.__hallUrlMap__.gongzuotai_url,
					platformType: '02', //钉钉
					authType:'03'//扫码登录 
				}).then((res)=>{
					if (res.retCode == 1) {
						let state = getQueryParam('state',res.data);
						let messageUrl=getUrlParam('messageUrl') || '';
						if(isMobile && !messageUrl.includes('/insurance/fe/workbenchServiceOrder/service_order/detail')){
							window.location.href=`${window.__51__commonUtil.__hallUrlMap__.hrmobile_url}/desktop`;
						}else{
							window.location.href=`${window.__51__commonUtil.__hallUrlMap__.home_url}/weixin/login?ttlurl=${window.location.host}&code=${encodeURIComponent(code)}&state=${encodeURIComponent(state)}&messageUrl=${encodeURIComponent(messageUrl)}&corpId=${encodeURIComponent(corpId)}`;
						}
					}
				})
			}});
	// });
  }else if(getUrlParam('weixinLogout')){
	//退出跳转扫码登录
	$axios.getLoginAuthLink({
		redirectUrl:window.__51__commonUtil.__hallUrlMap__.gongzuotai_url,
		platformType: isDingTalk() ? '02':'01', //钉钉
		authType:getUrlParam('authType') ? getUrlParam('authType') :'02'//扫码登录
	}).then((res)=>{
		if (res.retCode == 1) {
			window.location.href=res.data;
		}
	})
}else if(getUrlParam('state')){
	//state 第1-2位：01安装授权、02三方网站扫码登录授权、03企微网页登录授权  第3-4位：01企微
	let state = getUrlParam('state')
	//安装成功回跳地址
	if(state.substring(2,4) == '01'){
		if(state.substring(0,2) == '01'){
			//安装成功回调接口
			$axios.installCallback({
				authCode:getUrlParam('auth_code'),
				state:state,
				expiresIn:getUrlParam('expiresIn'),
			}).then(res=>{
				if(res.retCode == 1){
					$axios.getLoginAuthLink({
						redirectUrl:window.__51__commonUtil.__hallUrlMap__.gongzuotai_url,
						platformType:'01',
						authType:'02'//扫码登录
					}).then((res)=>{
						if (res.retCode == 1) {
							window.location.href=res.data;
						}
					})
				}
			});
		}
	}
	//授权成功回跳地址
	if(state.substring(0,2) == '02' || state.substring(0,2) == '03'){
		let code  = getUrlParam('code') || getUrlParam('auth_code');
		let messageUrl=getUrlParam('messageUrl') || '';
		if(isMobile && (isWeCom || isDingTalk) && !messageUrl.includes('/insurance/fe/workbenchServiceOrder/service_order/detail')){
			window.location.href=`${window.__51__commonUtil.__hallUrlMap__.hrmobile_url}/desktop`;
		}else{
			window.location.href=`${window.__51__commonUtil.__hallUrlMap__.home_url}/weixin/login?ttlurl=${window.location.host}&code=${encodeURIComponent(code)}&state=${encodeURIComponent(state)}&messageUrl=${encodeURIComponent(messageUrl)}`;
		}
	}
}else{
	if(token || window.location.pathname.includes('workWeixin/install')){
		renderVue();
	}else{
		jsonpLogin();
	}
}
function renderVue (){
	Vue.directive('ieplaceholder', {
		inserted: function (el, binding) {
			if (!('placeholder' in document.createElement('input'))) {
				el.onblur = function () {
					if (!el.value) {
						el.value = binding.value
					}
				}
				el.onfocus = function () {
					if (el.value == binding.value) {
						el.value = ''
					}
				}
			}
		},
		update: function (el, binding) {
			if (!el.value && !('placeholder' in document.createElement('input'))) {
				if (el.showplace) {
					return
				}
				el.showplace = true
				el.blur()
				el.value = binding.value
			}
		}
	})
	if(!window.location.pathname.includes('workWeixin/install')){
		$axios.getBaseInfo({isCheckBusinessType:true}).then(res=>{
			if(res.retCode == 1){
				handleAddWatermark({
					username: res.data.userName,
					phone: res.data.userPhone
				});
				appStore.setBaseInfo(res.data);
				localStorage.setItem('baseInfo',JSON.stringify(res.data));
				setTimeout(()=>{
					clientQimoor(res.data);
				},3000);
				setCompanyId(res.data.companyId);
				window.companyId = res.data.companyId;
				if(getUrlParam('nav')){
					// localStorage.setItem('permission',false);
					if(getUrlParam('nav') == 0){ 
	
						document.title = "首页__101HR人力资源管理平台";
					}
					if(getUrlParam('nav') == 2){
						document.title = "保险__101HR人力资源管理平台";
					}
					if(getUrlParam('nav') == 5){
						document.title = "数据分析__101HR人力资源管理平台";
						
					}
					if(getUrlParam('nav') == 6){
						document.title = "服务单查询__101HR人力资源管理平台";
						
					}
				}
	
				localStorage.setItem('permission',true);
	
				//小库商户跳转保险大厅首页
				if(res.data.businessId == 1){
					window.location.href = window.__51__commonUtil.__hallUrlMap__.baoxian_url;
				}else{
					renderNewVue();
				}
			}else if(res.retCode == 27007){//未签属有效合同
				localStorage.setItem('permission',false);
				renderNewVue();
			}
		});

	}else{
		router.beforeEach((to, from, next) => {
			if (to.path.includes('workWeixin/install') && isMobile) {
				window.location.href = `${window.__51__commonUtil.__hallUrlMap__.hrmobile_url}/desktop`;
			}else{
				next();
			}
		});
		new Vue({
			router,
			pinia,
			render: h => h(App)
		}).$mount('#app')
	}
};
function renderNewVue(){
	router.beforeEach((to, from, next) => {
    const baoxiaoPathList = [
      '/insurance/fe/add_insurance/index',
      '/insurance/fe/specialOrder/index',
      '/insurance/fe/stop_insurance/handle',
      '/insurance/fe/insurance/stop_insurance_batch',
      '/insurance/fe/multipleAddInsurance/add_insurance',
      '/insurance/fe/batchStopCommercial/index',
      '/insurance/fe/supple_payment/handle',
      '/insurance/fe/changeHospital/index',
      '/insurance/fe/reimbursement/handle',
      '/insurance-stage',
    ]
    if(baoxiaoPathList.includes(to.path)) {
      localStorage.setItem('currentNav', 2)
    }
    if(to.path.includes('/stats')) {
      localStorage.setItem('currentNav', 5)
    }
		if (to.meta.title) { // 如果设置标题，拦截后设置标题
			document.title = to.meta.title + "__101HR人力资源管理平台";
		}
		let baseInfo = JSON.parse(localStorage.getItem('baseInfo')) || {};
			// 登录判断
			if(['/stage'].includes(to.path)){
				if(showNps){
					getNpsInfo({
						type:'1',
						source:window.location.host
					});
				}
			}else{
				hideNpsBtn()
			}
			if(to.path.includes('/insurance') && !to.path.includes('/fe/workbenchServiceOrder')){
				appStore.getIsBaoxian(true);
			}else{
				appStore.getIsBaoxian(false);
			}

			let isBaoxianModule = false;
			if(to.path.includes('/insurance/fe') && !to.path.includes('/workbenchServiceOrder/') && !to.path.includes('/fe/bill/index') && !to.path.includes('/insurance/fe/specialOrder')){
				isBaoxianModule = true;
			}
      if (
          to.path !== '/nopermission' 
          && 
          (
            !JSON.parse(localStorage.getItem('permission'))||
            (!baseInfo.isInsuranceHallRule&&isBaoxianModule) ||
            (to.path.includes('/fe/bill/index') && !baseInfo.isCostHallRule) || 
            (!baseInfo.isInsuranceHallRule && to.path.includes('stats/baoxian') || 
            (!baseInfo.isPersonHallRule && to.path.includes('stats/ssc')) || 
            (!baseInfo.isSalaryHallRule && (to.path.includes('stats/xinchou') || 
            to.path.includes('stats/chengben'))) || 
            (!baseInfo.isSalaryHallRule && !baseInfo.isPersonHallRule && !baseInfo.isInsuranceHallRule && to.path.includes('stats')) || 
            (!baseInfo.isInsuranceHallRule && to.path.includes('insurance-stage')))
          )
        ) {
        if(!JSON.parse(localStorage.getItem('permission'))){
          next({ path: '/nopermission' });
        }else if((!baseInfo.isInsuranceHallRule&&isBaoxianModule) || (to.path.includes('/fe/bill/index') && !baseInfo.isCostHallRule)){ //没有保险权限 保险嵌入模块跳转无权限页面 除了集团账单、服务单查询、特单 
          localStorage.setItem('currentNav',2);
        }else if((!baseInfo.isInsuranceHallRule && to.path.includes('stats/baoxian') || (!baseInfo.isPersonHallRule && to.path.includes('stats/ssc')) || (!baseInfo.isSalaryHallRule && (to.path.includes('stats/xinchou') || to.path.includes('stats/chengben'))))){//数据分析模块无权限
          localStorage.setItem('currentNav',5);
        }
        next({ path: '/nopermission' });
      } else {
        changeQiankunLoading(true)

        if(baseInfo.isGroupCustomer && ['/', '/stage'].includes(to.path)){
          next({path: '/groupStage'})
        }else if(!baseInfo.isGroupCustomer && to.path == '/groupStage'){
          next({path: '/stage'})
        }else{
          next()
        }
      }
    })
		new Vue({
			router,
			pinia,
			directives: {
				clickoutside: {
					bind: function (el, binding) {
						function documentHandler(e) {
							// 判断点击的区域是否是指令所在的元素内部，如果是，就跳出函数，不往下执行。
							if (el.contains(e.target)) {
								return false
							}
							// 判断当前的指令v-clickoutside有没有写表达式
							if (binding.expression) {
								// binding.value()就是用来执行当前上下文methods中指定的函数的
								binding.value(e)
							}
						}
						// 用于在unbind钩子函数中移除对document的click事件监听。
						el.__vueClickOutside__ = documentHandler
						document.addEventListener('click', documentHandler)
					},
					unbind: function (el) {
						document.removeEventListener('click', el.__vueClickOutside__)
						// 如果不移除，当组件或元素销毁时，它仍存在于内存中
						delete el.__vueClickOutside__
					}
				}
			},
			render: h => h(App)
		}).$mount('#app')
}
router.onError(error => {
	const pattern = /Loading chunk (\d)+ failed/g
	const isChunkLoadFailed = error.message.match(pattern)
	const targetPath = router.history.pending.fullPath
	if (isChunkLoadFailed && error.type === 'missing') {
		router.push(targetPath)
	}
})

function handleAddWatermark({ username, phone }) {
	const markInfo = {
		content: `${username} ${phone.slice(-4)}`
	}
	if (window.__51__commonUtil && window.__51__commonUtil.watermark) window.__51__commonUtil.watermark(markInfo)
}

function clientQimoor(data) {
  (function(a, h, c, b, g) {
    var nosignScript = document.querySelector("#nosign");
    if (nosignScript) {
      return;
    }
    data ? a["qimoClientId"] = {
      userId: (data.userId + "_") || "", // 自定义用户的唯一id，不能传空字符串、null、中文，id由字母大小写、数字及'-'和 '_'组成
      customField: { // 扩展字段，json对象，对象中的key-value都自定义
        商户ID: data.companyId,
        商户名称: data.companyName,
        操作人ID: data.userId
      }
    } : a["qimoClientId"] = Math.random() + "_";
    g = h.createElement(c);
    g.async = 1;
    g.charset = "utf-8";
    g.src = b;
    g.setAttribute("id", "nosign");
    c = h.getElementsByTagName(c)[0];
    c.parentNode.insertBefore(g, c);
  })(window, document, "script", "https://classic-webchat.7moor.com/javascripts/7moorInit.js?accessId=910920a0-1a5c-11eb-a51a-1be58fb800cd&autoShow=false&language=ZHCN", "ud");
};