// leftMenu中用到 routes，注意同步修改
export const routes = [
	{
		path: '/',
		component: () => import('@/components/layout/index.vue'),
		redirect: '/stage',
		children: [
      {
        name: 'groupStage',
        meta: {
          active:'groupStage',
          title:'集团总租户首页'
        },
        path: 'groupStage',
        component: () => import('@/views/group/index.vue')
      },
			{
				name: 'stage',
				meta: {
					active:'stage',
					title:'首页'
				},
				path: 'stage',
				component: () => import('@/views/index.vue')
			},
			{
				name: 'statsRenshi',
				meta: {
					active:'/stats/ssc',
					title:'人事分析'
				},
				path: '/stats/ssc',
				component: () => import('@/views/stats/renshi.vue')
			},
      {
				name: 'statsBaoxian',
				meta: {
					active:'/stats/baoxian',
					title:'保险分析'
				},
				path: '/stats/baoxian',
				component: () => import('@/views/stats/baoxian.vue')
			},
      {
				name: 'statsXinchou',
				meta: {
					active:'/stats/xinchou',
					title:'薪酬分析'
				},
				path: '/stats/xinchou',
				component: () => import('@/views/stats/xinchou.vue')
			},
      {
				name: 'statsChengben',
				meta: {
					active:'/stats/chengben',
					title:'成本分析'
				},
				path: '/stats/chengben',
				component: () => import('@/views/stats/chengben.vue')
			},
			{
				path: '/nopermission',
				component: () => import('@/views/noPromission/index.vue'),
				meta: {
					// title: '无权限'
					// goBack: false // '/xxx/xx'
				}
			},
		]
	},
	{
		path: '/insurance/*',
		name: 'layout',
		component: () => import('@/components/layout/index.vue'),
		children:[
			// 集团账单模块
			{
				annotation: '集团账单',
				path: "/insurance/fe/bill/*",
				name: "GroupBill",
				meta: {
					isQianKun: true,
					active:"/insurance/fe/bill/index"
				},
			},
			//员工申请
			{
				annotation: '待处理员工申请',
				path: "/insurance/fe/employeeApply/*",
				name: "EmployeeApply",
				meta: {
					isQianKun: true,
					active:'/insurance/fe/employeeApply/index',
					title:'员工申请'
				},
			},
			{
				// 保险-单个上保险模块
				annotation: "单个上保险模块",
				path: "/insurance/fe/add_insurance/*",
				name: "AddInsurance",
				meta: {
				  isQianKun: true,
				},
			  },
			  {
				annotation: '保险服务单',
				name:'service',
				path: '/insurance/fe/service_order/*',
				meta: {
					active:'/insurance/fe/service_order/query',
					isQianKun: true,
					title:'业务办理'
				},
			  },
			  // 保险-服务单模块
			  {
				annotation: "服务单模块",
				path: "/insurance/fe/workbenchServiceOrder/*",
				name: "ServiceOrder",
				meta: {
				  isQianKun: true,
				  active:'insurance/fe/workbenchServiceOrder/service_order',
				  title:'服务单查询'
				},
			  },
			  // 保险-调基模块
			  {
				annotation: "调基模块",
				path: "/insurance/fe/adjust_base/*",
				name: "AdjustBase",
				meta: {
				  isQianKun: true,
				},
			  },
			  // 保险-集团账单
			  {
				annotation: "集团账单",
				path: "/insurance/fe/bill/*",
				name: "GroupBill",
				meta: {
				  isQianKun: true,
				  active:"/insurance/fe/bill/index"
				},
			  },
			  // 保险-待处理员工申请
			  {
				annotation: "待员工处理列表",
				path: "/insurance/fe/employeeApply/*",
				name: "EmployeeApply",
				meta: {
				  isQianKun: true,
				  title:'员工申请'
				},
			  },
			  // 保险-在缴明细
			  {
				annotation: "在缴明细",
				path: "/insurance/fe/inpayment_detail/*",
				name: "InPaymentDetail",
				meta: {
				  isQianKun: true,
				  active:'/insurance/fe/inpayment_detail/list',
				  title:'在缴明细'
				},
			  },
			  // 保险-报销模块
			  {
				annotation: "报销模块",
				path: "/insurance/fe/reimbursement/*",
				name: "reimbursement",
				meta: {
				  isQianKun: true,
				},
			  },
			  // 保险-批量停/上保险
			  {
				annotation: "批量模块",
				path: "/insurance/fe/insurance/*",
				name: "insurance",
				meta: {
				  isQianKun: true,
				},
			  },
			  {
				annotation: "批量上商保",
				path: "/insurance/fe/multipleAddInsurance/*",
				name: "multipleAddInsurance",
				meta: {
				  isQianKun: true,
				},
			  },
			  {
				annotation: "批量停商保",
				path: "/insurance/fe/batchStopCommercial/*",
				name: "batchStopCommercial",
				meta: {
				  isQianKun: true,
				},
			  },
			  {
				annotation: "定点医院",
				path: "/insurance/fe/changeHospital/*",
				name: "changeHospital",
				meta: {
				  isQianKun: true,
				},
			  },
			  {
				annotation: "其他业务",
				path: "/insurance/fe/specialOrder/*",
				name: "specialOrder",
				meta: {
				  isQianKun: true,
				},
			  },
			  {
				annotation: "补缴",
				path: "/insurance/fe/supple_payment/*",
				name: "supple_payment",
				meta: {
				  isQianKun: true,
				},
			  },
			  {
				annotation: "停保险",
				path: "/insurance/fe/stop_insurance/*",
				name: "stop_insurance",
				meta: {
				  isQianKun: true,
				},
			  },
		]
	},
	{
		path: '/workWeixin/install',
		component: () => import('@/views/workWeixin/install.vue'),
		meta: {
		}
	},
	{
		path: '*',
		component: () => import('@/views/404/index.vue')
	}
]
