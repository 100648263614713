import ShebaoMonitor from '@zh/monitor'
import router from '@/router'
import packageJson from '../../../package.json';

export function useSkyWalking(Vue) {
  Vue.use(ShebaoMonitor, {
    router,
    packageJson: packageJson,
    getUserInfo: () => {
      const baseInfo = JSON.parse(localStorage.getItem('baseInfo')) || {}
      return {
        userId: baseInfo.userId,
        userRealName: baseInfo.userName,
      }
    }
  })
}
