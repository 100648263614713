import * as Sentry from '@sentry/vue'

const installSentry = (app, router) => {
  if (import.meta.env.VITE_RUN_ENV != 'prod') {
    return
  }
  Sentry.init({
    app,
    dsn: 'https://8e1cb07303fd4799bc97770022c03566@sentrypublic.51shebao.com/9',
    integrations: [Sentry.browserTracingIntegration({ router, routeLabel: 'path' })],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  })
}
export default installSentry